import { startOfDay } from 'date-fns';

export const getDaysArray = function (start: Date, end: Date) {
  const arr = [];
  for (
    const dt = new Date(startOfDay(start));
    dt <= new Date(startOfDay(end));
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};
