import { type DietCatalogDetail } from '../types';
import { GET } from '@/lib/methods';

export const getDiet = async (dietId: string, params?: RequestInit) => {
  return GET<DietCatalogDetail>({
    url: process.env.NEXT_PUBLIC_CATALOG_URL + `/diets/${dietId}`,
    params: {
      next: {
        tags: ['diet', dietId],
        revalidate: 3600,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      ...params,
    },
  });
};

export const getDietBySlug = async (slug: string, params?: RequestInit) => {
  return GET<DietCatalogDetail>({
    url: process.env.NEXT_PUBLIC_CATALOG_URL + `/diets-slug/${slug}`,
    params: {
      next: {
        tags: ['diet', slug],
        revalidate: 3600,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      ...params,
    },
  });
};
