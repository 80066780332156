'use client';

import { getDaysArray } from '../../utils/get-days-array';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { format, isSameDay, max, min } from 'date-fns';

export const CarouselCalendar = ({
  dates,
  currentDate,
  setCurrentDate,
}: {
  dates: Date[];
  currentDate: Date;
  setCurrentDate: (date: string) => void;
}) => {
  // all dates between min and max - not excluding the ones between ordered dates
  const allDates = getDaysArray(min(dates), max(dates));

  // current date is active
  const isActive = (date: Date) => isSameDay(date, currentDate);

  // date outside of the order dates
  const isDisabled = (date: Date) => !dates.find((d) => isSameDay(d, date));

  // format date to locale
  const formatDate = (
    date: Date,
  ): { month: string; day: number; dayOfWeek: string } => {
    const dayOfWeek = date.toLocaleString('pl-PL', { weekday: 'short' });
    const day = date.getDate();
    const month = date.toLocaleString('pl-PL', { month: 'short' });
    return { month, day, dayOfWeek };
  };

  const dateInfo = (date: Date) => ({
    isActive: isActive(date),
    isDisabled: isDisabled(date),
    ...formatDate(date),
  });

  return (
    <ScrollArea className='flex-1 overflow-x-auto p-4 pb-4'>
      <div className='flex w-full gap-2 whitespace-nowrap p-0'>
        {allDates.map((date) => {
          const { isActive, isDisabled, month, day, dayOfWeek } =
            dateInfo(date);

          return (
            <div
              key={date.toISOString()}
              className={cn('h-[72px] w-[72px]')}
              onClick={() =>
                !isDisabled && setCurrentDate(format(date, 'yyyy-MM-dd'))
              }
            >
              <div
                className={cn(
                  'flex aspect-square h-[72px] w-[72px] cursor-pointer flex-col items-center justify-center rounded-lg border bg-white py-1',
                  {
                    'bg-brand-yellow border-transparent': isActive,
                    'bg-brand-grey2 cursor-default opacity-30': isDisabled,
                    'hover:bg-brand-yellow/20': !isActive && !isDisabled,
                  },
                )}
              >
                <span className='text-xs uppercase'>{dayOfWeek}</span>
                <span className='text-2xl font-bold'>{day}</span>
                <span className='text-xs uppercase'>{month}</span>
              </div>
            </div>
          );
        })}
      </div>
      <ScrollBar orientation='horizontal' className='lg:mt-2' />
    </ScrollArea>
  );
};
