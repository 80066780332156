import { type Meal } from '@/features/panel/types';
import { GET } from '@/lib/methods';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns/format';

type MenuReturnType = { meals: Meal[] };

type UseDietParams = {
  dietId: string;
  date: string;
  options?: Omit<
    UseQueryOptions<MenuReturnType, unknown, MenuReturnType, string[]>,
    'queryFn' | 'queryKey'
  >;
};

const useDietMeals = ({ dietId, date, options }: UseDietParams) => {
  return useQuery({
    queryKey: ['diet-meals', dietId, date],
    queryFn: async () => {
      return await GET<MenuReturnType>({
        url: `/api/diet/${dietId}/meals?date=${format(date, 'yyyy-MM-dd')}`,
      });
    },
    staleTime: Infinity,
    retry: 1,
    ...options,
  });
};

export { useDietMeals };
