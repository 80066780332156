import { TriggerProps } from '@/components/types';
import { Button } from '@/components/ui/button';
import { forwardRef } from 'react';

export const Trigger = forwardRef(function Trigger(
  { size, ...trigger }: TriggerProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      variant={trigger.variant}
      onClick={trigger.onClick}
      ref={ref}
      size={size}
      {...trigger}
    >
      {trigger.children ?? trigger.label}
    </Button>
  );
});
