import { ProductRating } from '../types/product-rating.type';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

export const RatingDistribution = ({
  distribution,
  count,
}: {
  distribution: ProductRating['distribution'];
  count: number;
}) => {
  const startingDistribution = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };

  const finalDistribution = { ...startingDistribution, ...distribution };

  const getColor = (value: number) => {
    if (value <= 2) return '[&>div]:bg-brand-red';
    if (value === 3) return '[&>div]:bg-brand-yellow';
    return '[&>div]:bg-brand-green-light';
  };

  return (
    <div className='flex flex-col flex-col-reverse gap-y-1 w-full'>
      {Object.keys(finalDistribution).map((key) => {
        const parsedKey = parseInt(key) as 1 | 2 | 3 | 4 | 5;
        return (
          <div key={key} className='flex gap-x-3 items-center w-full'>
            <span className='flex items-center justify-center text-brand-green2 w-6'>
              {key}
            </span>
            <Progress
              className={cn('bg-brand-grey1', getColor(parsedKey))}
              value={(finalDistribution[parsedKey] / count) * 100}
            />
            <span className='flex items-center justify-end text-right text-muted-foreground w-11'>
              {((finalDistribution[parsedKey] / count) * 100).toFixed(0)}%
            </span>
          </div>
        );
      })}
    </div>
  );
};
