'use client';

import { useDiet } from '../hooks/use-diet';
import { useDietMeals } from '../hooks/use-diet-meals';
import { type DietCatalog } from '../types';
import { DietDrawerHeader } from './diet-drawer-header';
import { DietMenuItem } from './diet-menu-item';
import EmptyMenu from '@/assets/icons/empty-menu.svg';
import ShareIcon from '@/assets/icons/share.svg';
import { Loader } from '@/components/common/loader';
import { NoResults } from '@/components/common/no-results';
import { Button } from '@/components/ui/button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { CarouselCalendar } from '@/features/panel/components/order-details/carousel-calendar';
import { getNextDates } from '@/lib/date-helpers';
import { format } from 'date-fns';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

type DietDetailsProps = {
  dietId: string;
  share: boolean;
  showCta?: boolean;
};

export const DietMenu = ({
  dietId,
  share = true,
  showCta = true,
}: DietDetailsProps) => {
  const t = useTranslations('diets');
  const dates = getNextDates(14);
  const [currentDate, setCurrentDate] = useState(
    new Date(format(new Date(), 'yyyy-MM-dd')),
  );

  const { data, isLoading } = useDiet({ dietId });
  const { data: mealsData, isLoading: isMealsLoading } = useDietMeals({
    dietId,
    date: format(currentDate, 'yyyy-MM-dd'),
  });

  const handleChangeDate = (date: Date) => {
    setCurrentDate(date);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <NoResults />;
  }

  return (
    <>
      {share && (
        <Button
          variant='ghost'
          className='absolute right-6 top-4 h-6 min-h-0 p-0.5 md:right-20 md:top-6'
          onClick={() => alert('Share')}
        >
          <ShareIcon />
        </Button>
      )}

      <div className='flex w-full flex-col' data-pw='check-menu-modal'>
        <DietDrawerHeader data={data} showCta={showCta} />
        <div className='h-[calc(100vh-288px)] pb-4 md:h-[calc(100vh-257px)] md:pb-6'>
          <div className='bg-brand-grey4 relative px-0 pb-2 md:px-8'>
            <CarouselCalendar
              currentDate={currentDate}
              dates={dates.map((date) => new Date(date))}
              setCurrentDate={(date: string) =>
                handleChangeDate(new Date(date))
              }
            />
          </div>
          {!isMealsLoading ? (
            <ScrollArea className='h-full px-4 pt-6 md:px-12'>
              {(mealsData?.meals?.length ?? 0 > 0) ? (
                <div className='flex flex-col gap-3 pb-8'>
                  {mealsData?.meals.map((meal) => {
                    return <DietMenuItem key={meal.mealId} meal={meal} />;
                  })}
                </div>
              ) : (
                <div className='bg-background grid place-items-center gap-4 rounded-lg px-6 py-8 text-lg shadow-xs'>
                  <EmptyMenu />
                  {t('errors.mealsNotFound')}
                </div>
              )}
              <ScrollBar orientation='vertical' />
            </ScrollArea>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};
