import { StarRating } from './star-rating';
import { RatingDistribution } from '@/features/rating/components/rating-distribution';
import { ProductRating } from '@/features/rating/types/product-rating.type';
import { useTranslations } from 'next-intl';

export const RatingDetails = ({ rating }: { rating: ProductRating }) => {
  const t = useTranslations('rating');
  if (!rating) return null;
  return (
    <div className='grid md:grid-cols-4 gap-4 md:py-2'>
      <div className='flex flex-col w-full gap-y-2 justify-center items-center md:items-start md:border-r md:border-border'>
        <h3 className='text-3xl font-bold'>{rating.average.toFixed(1)}</h3>
        {/* Star rating */}
        <StarRating rating={rating.average} />
        <p className='text-muted-foreground text-sm'>
          {t('reviewsCount', { count: rating.count })}
        </p>
      </div>
      <div className='flex flex-col items-start md:col-span-3'>
        <RatingDistribution
          distribution={rating.distribution}
          count={rating.count}
        />
      </div>
    </div>
  );
};
