'use client';

import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { type HTMLAttributes } from 'react';

type LoaderProps = HTMLAttributes<HTMLDivElement> & {
  radius?: number;
  dots?: number;
};

export const Loader = ({
  className,
  radius = 20,
  dots = 8,
  ...props
}: LoaderProps) => {
  return (
    <div className='grid w-full place-items-center py-4'>
      <div className={cn('relative h-16 w-16', className)} {...props}>
        {[...Array(dots)].map((_, index) => {
          const angle = (index / dots) * (2 * Math.PI);
          const x = radius * Math.cos(angle);
          const y = radius * Math.sin(angle);

          return (
            <motion.div
              key={index}
              className='bg-primary absolute h-3 w-3 rounded-full'
              style={{
                left: `calc(40% + ${x}px)`,
                top: `calc(40% + ${y}px)`,
              }}
              animate={{
                scale: [0, 1, 0],
                opacity: [0, 1, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                delay: (index / dots) * 1.5,
                ease: 'easeInOut',
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
