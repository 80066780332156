'use client';

import { RatingDetails } from './rating-details';
import ClassicLoader from '@/components/common/classic-loader';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { getProductRating } from '@/features/rating/api/get-product-rating';
import { useQuery } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

type Props = {
  productId: string;
  productName?: string;
  trigger?: React.ReactNode;
};

const useProductRating = (productId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: ['product-rating', productId],
    queryFn: () => getProductRating(productId),
    enabled,
    retry: false,
  });
};

export const RatingsDialog = ({ productId, trigger, productName }: Props) => {
  const [open, setOpen] = useState(false);
  const t = useTranslations('rating');
  const {
    data: rating,
    isPending,
    isFetching,
    isError,
  } = useProductRating(productId, open);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
  };

  const loading = isPending || isFetching;

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='text-2xl font-medium text-left'>
            {productName
              ? t('dialogTitleProduct', { product: productName })
              : t('dialogTitle')}
          </DialogTitle>
        </DialogHeader>
        {loading && (
          <div className='flex justify-center items-center h-8'>
            <ClassicLoader />
          </div>
        )}
        {!loading && rating && <RatingDetails rating={rating} />}
        {isError && (
          <div className='h-8'>
            <p className='text-brand-green2'>{t('error')}</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
