import { Img } from '../image';
import { Link, type LocalizedRoute } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { type HTMLAttributes } from 'react';

type ListingItemCateringImageProps = HTMLAttributes<HTMLDivElement> & {
  name: string;
  imageUrl: string;
  url?: LocalizedRoute;
};

export const ListingItemCateringImage = ({
  url,
  name,
  imageUrl,
  ...props
}: ListingItemCateringImageProps) => {
  const classes =
    'border-brand-grey2 flex h-16 w-16 items-center justify-center rounded-sm border bg-white p-1.5 shadow-[0_2px_3px_0px_hsla(0,0%,0%,0.08)] transition-shadow duration-500 hover:shadow-[0_3px_3px_1px_hsla(0,0%,0%,0.1)]';

  const image = imageUrl.length > 0 ? imageUrl : null;
  return url ? (
    <Link href={url} title={name} className={cn(classes, props.className)}>
      {image && <Img src={image} alt={name} width={53} height={53} />}
    </Link>
  ) : (
    <div className={cn(classes, props.className)}>
      {image && <Img src={image} alt={name} width={53} height={53} />}
    </div>
  );
};
