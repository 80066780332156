import { getDiet } from '../api/get-diet';
import { type DietCatalogDetail } from '@/features/diets/types';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

type UseDietParams = {
  dietId: string;
  options?: Omit<
    UseQueryOptions<DietCatalogDetail, unknown, DietCatalogDetail, string[]>,
    'queryFn' | 'queryKey'
  >;
};

const useDiet = ({ dietId, options }: UseDietParams) => {
  return useQuery({
    queryKey: ['diet', dietId],
    queryFn: () => getDiet(dietId),
    ...options,
  });
};

export { useDiet };
