'use client';

import { useDiet } from '../hooks/use-diet';
import { type DietCatalog } from '../types';
import { DietDrawerHeader } from './diet-drawer-header';
import ShareIcon from '@/assets/icons/share.svg';
import { Loader } from '@/components/common/loader';
import { NoResults } from '@/components/common/no-results';
import { Img } from '@/components/image';
import { Prose } from '@/components/prose';
import { Button } from '@/components/ui/button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';

type DietDetailsProps = {
  dietId: string;
  share: boolean;
  showCta?: boolean;
};

export const DietDetails = ({
  dietId,
  share = true,
  showCta = true,
}: DietDetailsProps) => {
  const { data, isLoading } = useDiet({ dietId });

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <NoResults />;
  }

  return (
    <>
      {share && (
        <Button
          variant='ghost'
          className='absolute right-6 top-4 h-6 min-h-0 p-0.5 md:right-20 md:top-6'
          onClick={() => alert('Share')}
        >
          <ShareIcon />
        </Button>
      )}

      <div className='flex w-full flex-col'>
        <DietDrawerHeader data={data} showCta={showCta} />
        <div className='h-[calc(100vh-192px)] py-4 md:h-[calc(100vh-144px)] md:py-6'>
          <ScrollArea className='h-full px-4 md:px-12'>
            <Prose className='leading-6'>
              <div>{data.description}</div>
            </Prose>
            <div className='relative mt-8 pb-[45%] xl:h-80 xl:pb-0'>
              <Img
                src={data.imageUrl}
                alt={data.name}
                fill
                sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                className='rounded-lg object-cover'
              />
            </div>
            <ScrollBar orientation='vertical' />
          </ScrollArea>
        </div>
      </div>
    </>
  );
};
