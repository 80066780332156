'use client';

import { Trigger } from '@/components/trigger';
import { DrawerProps } from '@/components/types';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { cn } from '@/lib/utils';

const Header = ({
  title,
  description,
  headerClassName,
}: Pick<DrawerProps, 'title' | 'description'> & {
  headerClassName?: string;
}) => {
  if (!title) return null;

  const HeaderDescription = () =>
    description ? <SheetDescription>{description}</SheetDescription> : null;

  return (
    <div className={cn('w-full', headerClassName)}>
      <SheetHeader className='border-input w-full border-b py-4'>
        <SheetTitle className='text-center font-medium'>{title}</SheetTitle>
        <HeaderDescription />
      </SheetHeader>
    </div>
  );
};

export const Drawer = ({
  children,
  title,
  description,
  trigger,
  side = 'right',
  className,
  contentClassName,
  headerClassName,
  cover = false,
  ...rest
}: DrawerProps) => (
  <Sheet {...rest}>
    <SheetTrigger asChild>
      <Trigger {...trigger} />
    </SheetTrigger>
    <SheetContent
      side={side}
      className={cn(
        'fixed items-start border-transparent bg-transparent p-0 dark:border-transparent dark:bg-transparent [&>button>svg]:h-6 [&>button>svg]:w-6 [&>button]:left-4 [&>button]:right-auto [&>button]:top-4 [&>button]:z-10 [&>button]:opacity-100',
        className,
        { 'h-full': side === 'left' || side === 'right', 'w-full': cover },
      )}
      onOpenAutoFocus={(event) => {
        event.preventDefault();
      }}
    >
      <div
        className={cn(
          `bg-background flex w-full flex-col items-start overflow-y-scroll`,
          {
            'h-dvh': side === 'left' || side === 'right',
            'w-screen': side === 'top' || side === 'bottom',
          },
          {
            'rounded-l-(--radius)': side === 'right' && !cover,
            'rounded-r-(--radius)': side === 'left' && !cover,
            'rounded-t-(--radius)': side === 'bottom' && !cover,
            'rounded-b-(--radius)': side === 'top' && !cover,
          },
        )}
      >
        <Header
          title={title}
          description={description}
          headerClassName={headerClassName}
        />
        <div
          className={cn('flex w-full grow px-4 py-6', contentClassName)}
        >
          {children}
        </div>
      </div>
    </SheetContent>
  </Sheet>
);
