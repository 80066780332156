'use client';

import { type ButtonProps, buttonVariants } from '@/components/ui/button';
import { Link, getLocaleForPathname, getPathname } from '@/i18n/navigation';
import { getConfiguratorParams, objectToQueryString } from '@/lib/utilities';
import { cn } from '@/lib/utils';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import type { JSX } from 'react';

type AddDietToCartProps = {
  dietSlug: string;
  className?: string;
  button?: ButtonProps & {
    icon?: JSX.Element;
    label?: string;
  };
};

export const AddDietToCart = ({ dietSlug, className }: AddDietToCartProps) => {
  const t = useTranslations('diets');
  const searchParams = useSearchParams();
  const configParams = getConfiguratorParams(searchParams);
  const locale = getLocaleForPathname();

  return (
    <Link
      data-pw='configure-diet-button-drawer'
      href={
        getPathname({
          href: {
            pathname: '/configure-diet/[slug]',
            params: { slug: dietSlug },
          },
          locale,
        }) + objectToQueryString(configParams)
      }
      className={cn(
        buttonVariants({ variant: 'secondary', size: 'sm' }),
        'mt-2 h-10 w-auto rounded-lg px-4 py-2 font-medium md:h-10 xl:h-12',
        className,
      )}
      prefetch={true}
    >
      <span>{t('configure')}</span>
      <IconArrowNarrowRight stroke={1.25} className='ml-2 hidden md:block' />
    </Link>
  );
};
