import NoResultsIcon from '@/assets/icons/empty-search.svg';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { type HTMLAttributes } from 'react';

type LoaderProps = HTMLAttributes<HTMLDivElement> & {
  withIcon?: boolean;
  title?: string;
  subtitle?: string;
  button?: React.ReactNode;
};

export const NoResults = ({
  className,
  withIcon = false,
  title,
  subtitle,
  button,
  ...props
}: LoaderProps) => {
  const t = useTranslations();
  return (
    <div
      className={cn(
        'relative flex w-full flex-col items-center justify-center py-4',
        className,
      )}
      {...props}
    >
      {withIcon && <NoResultsIcon className='mb-4' />}
      {title && <h2 className='text-3xl font-medium'>{title}</h2>}
      {subtitle ? (
        <p className='my-4 text-xl'>{subtitle}</p>
      ) : (
        <p>{t('common.no_results')}</p>
      )}
      {button && <div className='mt-2'>{button}</div>}
    </div>
  );
};
