import { calculateAvailabilityDate } from '../utilities';
import {
  type DietCatalog,
  type DietCatalogDetail,
} from '@/features/diets/types';
import { addDays, format, startOfDay } from 'date-fns';
import { pl } from 'date-fns/locale';

const useDietDates = ({
  diet,
  saturdays,
  sundays,
}: {
  diet: DietCatalogDetail | DietCatalog;
  saturdays: boolean;
  sundays: boolean;
}) => {
  const firstPossibleOrderDay = calculateAvailabilityDate(
    diet.availability,
    'date',
  ) as Date;

  const lastPossibleOrderDay = diet.catering.maximumDaysAllowedToOrderInFuture
    ? addDays(new Date(), diet.catering.maximumDaysAllowedToOrderInFuture)
    : undefined;

  const lastPossibleOrderDayFormatted = lastPossibleOrderDay
    ? format(lastPossibleOrderDay, 'dd.MM.yyyy')
    : '';

  const disabledDays = diet.catering.disabledDays.map((day) => new Date(day));

  const isDisabledDate = (day: Date) => {
    if (!saturdays && day.getDay() === 6) return true;
    if (!sundays && day.getDay() === 0) return true;
    if (disabledDays.find((date) => date.toDateString() === day.toDateString()))
      return true;
    if (
      firstPossibleOrderDay &&
      startOfDay(day) < startOfDay(firstPossibleOrderDay)
    )
      return true;
    return false;
  };

  // find the next available date
  const findNextAvailableDate = (date: Date): Date => {
    const nextDay = addDays(date, 1);
    if (!isDisabledDate(nextDay)) return nextDay;
    return findNextAvailableDate(nextDay);
  };

  // find the next [count] available dates from the selected date
  const findNextAvailableDatesArray = (date: Date, count: number) => {
    const nextDays = [];
    let nextDay = date;
    for (let i = 0; i < count; i++) {
      nextDay = findNextAvailableDate(nextDay);
      nextDays.push(nextDay);
    }
    return nextDays;
  };

  return {
    nextPossibleDay: (date: Date) => findNextAvailableDate(date),
    nextPossibleDays: (date: Date, count: number) =>
      findNextAvailableDatesArray(date, count),
    // returns "Wtorek", "Środa", etc.
    nextPossibleDayFormatEeee: (date: Date) =>
      format(findNextAvailableDate(date), 'eeee', { locale: pl }),
    areDatesAvailable: (dates: Date[]) => {
      return dates.every((date) => !isDisabledDate(date));
    },
    lastPossibleOrderDay,
    lastPossibleOrderDayFormatted,
  };
};

export { useDietDates };
