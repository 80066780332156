import { cn } from '@/lib/utils';

export const Prose = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): React.ReactNode => {
  return (
    <article
      className={cn(
        'prose dark:prose-invert prose-img:rounded-xl prose-headings:underline prose-gray w-full max-w-none',
        className,
      )}
    >
      {children}
    </article>
  );
};
