import { cn } from '@/lib/utils';
import { type HTMLAttributes } from 'react';

type LoaderProps = HTMLAttributes<HTMLDivElement> & { innerClassName?: string };

export default function ClassicLoader({ className }: LoaderProps) {
  return (
    <div
      className={cn(
        'border-current h-4 w-4 animate-spin rounded-full border-2 border-t-transparent',
        className,
      )}
    />
  );
}
