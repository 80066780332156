import { Badge } from '@/components/ui/badge';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { type Meal } from '@/features/panel/types';
import { useTranslations } from 'next-intl';

type DietMenuItemProps = {
  meal: Meal;
};

export const DietMenuItem = ({ meal }: DietMenuItemProps) => {
  const t = useTranslations();
  return (
    <Card className='bg-background border p-4 lg:p-6'>
      <CardHeader className='p-0'>
        <div className='flex w-full items-center justify-between'>
          <CardTitle className='text-wrap break-words text-xl font-normal md:w-1/2 xl:w-2/3'>
            {meal.label}
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className='m-0 p-0 font-light'>
        <div>{meal.dish.name}</div>
        <div className='flex flex-wrap gap-2.5 lg:gap-5'>
          <span>
            <span className='font-bold'>{meal.dish.kcal}</span>
            <span> {t('common.kcalString')}</span>
          </span>
          <span>
            <span>{t('nutrition.proteinShort')}: </span>
            <span className='font-bold'>{meal.dish.nutrition.protein}g</span>
          </span>
          <span>
            <span>{t('nutrition.carbsShort')}: </span>
            <span className='font-bold'>{meal.dish.nutrition.carbs}g</span>
          </span>
          <span>
            <span>{t('nutrition.fatShort')}: </span>
            <span className='font-bold'>{meal.dish.nutrition.fat}g</span>
          </span>
        </div>
      </CardContent>
      <CardFooter className='flex flex-wrap gap-2 p-0'>
        {meal.dish.tags?.map((tag) => (
          <Badge
            variant='outline'
            key={tag.label}
            className='text-brand-black/60 cursor-default px-2 py-1.5 font-normal'
          >
            {tag.label}
          </Badge>
        ))}
      </CardFooter>
    </Card>
  );
};
