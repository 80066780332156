'use client';

import StarIcon from '@/assets/icons/star.svg';
import { Badge, type BadgeProps } from '@/components/ui/badge';
import { RatingsDialog } from '@/features/rating/components/ratings-dialog';
import { cn } from '@/lib/utils';
import { IconTrendingUp } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';

const sizes = {
  sm: {
    wrapperClasses: 'px-2 py-1',
    iconClasses: 'h-3 w-3',
    textClasses: 'text-[11px]',
  },
  md: {
    wrapperClasses: 'px-2 py-1.5',
    iconClasses: 'h-4 w-4',
    textClasses: 'text-sm md:text-xs',
  },
};

type RatingProps = BadgeProps & {
  rating: number;
  count: number;
  isTrending?: boolean;
  productId: string;
  productName?: string;
  size?: keyof typeof sizes;
  ratingClassName?: string;
  trendingClassName?: string;
  details?: boolean;
};

export const Rating = ({
  rating,
  count,
  isTrending,
  size = 'sm',
  className,
  productId,
  productName,
  ratingClassName,
  trendingClassName,
  details,
  ...props
}: RatingProps) => {
  const t = useTranslations();
  const serializedRating = rating ?? 0;
  const serializedCounter = count ?? 0;
  const displayRating = serializedRating.toFixed(1);

  const RatingBadge = () => (
    <Badge
      variant='outline'
      className={cn(
        'bg-brand-grey1 rounded-full border-0',
        sizes[size].wrapperClasses,
        className,
        ratingClassName,
        details && 'cursor-pointer hover:bg-brand-grey2',
      )}
      {...props}
    >
      <p
        className={cn(
          'flex items-center gap-1 leading-4 font-medium',
          sizes[size].textClasses,
        )}
      >
        <StarIcon
          className={cn('*:stroke-secondary', sizes[size].iconClasses)}
        />
        <span className='text-brand-green1'>{displayRating}</span>
        <span className='text-brand-green2'>({serializedCounter})</span>
      </p>
    </Badge>
  );

  const TrendingBadge = () => (
    <Badge
      variant='outline'
      className={cn(
        'bg-brand-yellow-lighter rounded-full border-0',
        sizes[size].wrapperClasses,
        className,
        trendingClassName,
      )}
    >
      <p
        className={cn(
          'flex items-center gap-1 leading-4 font-medium',
          sizes[size].textClasses,
        )}
      >
        <IconTrendingUp className='h-4 w-4 text-brand-yellow' />
        <span className='text-brand-yellow'>{t('common.trending')}</span>
      </p>
    </Badge>
  );

  if (serializedRating === 0 || !serializedRating || !serializedCounter)
    return null;

  return (
    <>
      {details && (
        <RatingsDialog
          productId={productId}
          productName={productName}
          trigger={<RatingBadge />}
        />
      )}
      {!details && <RatingBadge />}
      {isTrending && <TrendingBadge />}
    </>
  );
};
