import { type DietCatalogDetail } from '../types';
import { AddDietToCart } from './diet-add-to-cart';
import { ListingItemCateringImage } from '@/components/listing/listing-item-catering-image';
import { useDietDates } from '@/lib/hooks/use-diet-dates';
import { cn } from '@/lib/utils';
import { IconCircle } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';

export const DietDrawerHeader = ({
  data,
  className,
  showCta = true,
}: {
  data: DietCatalogDetail;
  className?: string;
  showCta?: boolean;
}) => {
  const t = useTranslations('diets');
  const dietDates = useDietDates({
    diet: data,
    saturdays: true,
    sundays: true,
  });
  return (
    <header
      className={cn(
        'bg-brand-grey4 relative mt-[60px] flex items-center gap-4 px-4 pb-4 pt-4 md:mt-0 md:px-12 md:pr-28 md:pt-6',
        className,
      )}
    >
      <ListingItemCateringImage
        name={data.catering.name}
        imageUrl={data.catering.logo.url}
        className='hidden md:flex'
      />
      <div>
        <h1 className='text-lg font-medium md:text-xl'>
          {data.name}
          <IconCircle
            stroke={1}
            className='mx-2 inline-block h-1.5 w-1.5 rounded-full border border-black'
          />
          <span className='whitespace-nowrap'>{data.catering.name}</span>
        </h1>
        <div className='flex flex-wrap items-center gap-x-4 text-sm md:mt-1 md:text-base'>
          <p className='whitespace-nowrap'>
            <span className='mr-1'>{t('from')}</span>
            <strong className='mr-1 inline-block font-bold leading-6'>
              {data.basePriceNumeric} zł
            </strong>
            <span>/ {t('day')}</span>
          </p>
          <p className='whitespace-nowrap'>
            <span className='mr-1'>{t('available_on')}:</span>
            <span className='inline-block font-bold capitalize'>
              {dietDates.nextPossibleDayFormatEeee(new Date())}
            </span>
          </p>
        </div>
        {showCta && <AddDietToCart className='xl:h-10' dietSlug={data.slug} />}
      </div>
    </header>
  );
};
