import HalfStar from '@/assets/icons/half-star.svg';
import Star from '@/assets/icons/star.svg';

export const StarRating = ({
  rating,
  scale = 5,
}: {
  rating: number;
  scale?: number;
}) => {
  // Calculate the number of full stars
  const fullStars = Math.floor(rating);
  // Calculate the number of half stars
  const halfStars = Math.round(rating - fullStars);
  // Calculate the number of empty stars
  const emptyStars = scale - fullStars - halfStars;

  return (
    <div className='flex items-center gap-1'>
      {Array.from({ length: fullStars }).map((_, index) => (
        <Star key={index} className='[&>path]:fill-brand-yellow' />
      ))}
      {halfStars > 0 && <HalfStar />}
      {emptyStars > 0 && <Star className='[&>path]:fill-brand-grey3' />}
    </div>
  );
};
